import React, { useState } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import AhubDownloadList from 'components/Install/AhubDownloadList';
import queryString from 'query-string';
import globalStyles from 'pages/pages.module.css';
import styles from 'pages/install/install.module.css';
import s from 'components/Install/Ahub/Ahub.module.css';
import _get from 'lodash/get';
import UAParser from 'ua-parser-js';

const WIN_OS_LIST = ['Windows'];
const LIN_OS_LIST = ['Linux', 'Unix', 'Ubuntu', 'CentOS', 'Fedora', 'FreeBSD', 'Debian'];

const Ahub = () => {
  const uaParser = new UAParser();
  const detectedOs = _get(uaParser.getOS(), 'name', '');
  const query = queryString.parse(typeof window !== 'undefined' ? window.location.search : '');

  const [isLinux, setIsLinux] = useState(() => query.ahub && detectedOs && ~LIN_OS_LIST.indexOf(detectedOs));
  const [isWindows, setIsWindows] = useState(query.ahub && detectedOs && (~WIN_OS_LIST.indexOf(detectedOs) || !~LIN_OS_LIST.indexOf(detectedOs)));

  return (
    <section className={cx(globalStyles.content, styles.wrapper, s.root)}>
      <div className={globalStyles.wrapper}>
        <div className={globalStyles.contentRow}>
          <div className={styles.titleContainer}>
            <h3 id="ahub" className={cx(styles.title)}>ASIC Hub</h3>
          </div>
          <div className={styles.content}>
            <div className={styles.dropdown}>
              <div className={cx(styles.dropdown)}>
                <p style={{width: "100%", marginBottom: "10px"}}>
                  <FormattedMessage id="ASIC.hub.chooseOS" defaultMessage="Choose OS" />
                </p>
                <AhubDownloadList isWindows={isWindows} isLinux={isLinux} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Ahub;
