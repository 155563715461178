import React from 'react';
// import clipboard from 'utils/images/clipboard.svg';
import copy from 'copy-to-clipboard';
import s from 'components/CommandCopy/CommandCopy.module.css';

const CommandCopy = ({ text }) => (
  <code className={s.pointer} onClick={() => copy(text)}>
    {text}
    {/* {' '}
    <img className={s.copyImg} src={clipboard} alt="clipboard" /> */}
  </code>
)

export default CommandCopy;
