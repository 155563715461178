import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import globalStyles from 'pages/pages.module.css';
import styles from 'pages/install/install.module.css';
import Link from 'components/Link';

const Beta = () => (
  <>
    <section className={cx(globalStyles.content, styles.wrapper)}>
      <div className={globalStyles.wrapper}>
        <div className={globalStyles.contentRow}>
          <div className={styles.titleContainer}>
            <h3 style={{fontSize:"38px"}} className={styles.title}>
              <FormattedMessage id="Beta.title" defaultMessage="Beta Image" />
            </h3>

          </div>
          <div className={styles.content}>
          <p style={{width: "100%"}}>
            <FormattedMessage id="Beta.caption2" defaultMessage="May use the latest drivers and experimental features." />
          </p>
            <Link external noRef href="https://download.hiveos.farm/beta/?torrent" style={{maxHeight: "50px"}} className={cx(globalStyles.btnPrimary, globalStyles.w45)} target="_self">
              <span className={globalStyles.whiteColor}>Torrent</span>
            </Link>
            <Link external noRef href="https://download.hiveos.farm/beta/" style={{maxHeight: "50px"}} className={cx(globalStyles.btnPrimary, globalStyles.w45)} target="_self">
              <span className={globalStyles.whiteColor}>ZIP</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Beta;
