import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import AsicBtn from 'components/AsicBtn';
import globalStyles from 'pages/pages.module.css';
import styles from 'pages/install/install.module.css';

const Guides = () => (
  <section className={cx(globalStyles.content, styles.wrapper)}>
    <div className={globalStyles.wrapper}>
      <div className={globalStyles.contentRow}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}><FormattedMessage id="Guides.title" defaultMessage="Documentation" /></h3>
        </div>
        <div className={styles.content}>
          <div className={styles.dropdown}>
          <p style={{width: "100%", marginBottom: "10px"}}>
            <FormattedMessage id="Guides.caption1-bm" defaultMessage="System Installation Guide" />
          </p>
          <AsicBtn href="https://download.bigminer.io/guides/GPU.pdf" rel="nofollow noopener noreferrer">
            <FormattedMessage id="Guides.caption2-bm" defaultMessage="View" />
          </AsicBtn>
          </div>

          <div className={styles.dropdown}>
            <p style={{width: "100%", marginBottom: "10px"}}>
              <FormattedMessage id="Guides.caption3-bm" defaultMessage="ASIC firmware flashing guide" />
            </p>
            <AsicBtn href="https://download.bigminer.io/guides/ASIC.pdf" rel="nofollow noopener noreferrer">
              <FormattedMessage id="Guides.caption2-bm" defaultMessage="View" />
            </AsicBtn>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Guides;
