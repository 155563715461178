import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import globalStyles from 'pages/pages.module.css';
import styles from 'pages/install/install.module.css';
import Link from 'components/Link';

const DisklessPxe = () => (
    <section className={cx(globalStyles.content, styles.wrapper)}>
        <div className={globalStyles.wrapper}>
            <div className={globalStyles.contentRow}>
                <div className={styles.titleContainer}>
                <Link external noRef href="https://forum.hiveos.farm/t/hive-os-diskless-pxe/12319">
                  <h3 className={styles.title}>
                    <FormattedMessage id="DisklessPxe.hiveOs" defaultMessage="Hive OS" />
                    <br />
                    <FormattedMessage id="DisklessPxe.disklessPxe" defaultMessage="Diskless PXE" />
                  </h3>
                </Link>
                </div>
                <div className={styles.content}>
                  <p className={cx(styles.text, globalStyles.w100)}>
                    <FormattedMessage
                      id="DisklessPxe.caption"
                      defaultMessage="Solution for diskless rigs and server to boot them over the network {hiveDisklessLink} will allow you to reduce the cost of your mining investment by saving on disk media"
                      values={{ hiveDisklessLink: <Link external noRef href="https://forum.hiveos.farm/t/hive-os-diskless-pxe/12319" className={globalStyles.link}>Hive OS Diskless PXE</Link> }}
                    />
                  </p>
                </div>
            </div>
        </div>
    </section>
)

export default DisklessPxe;
