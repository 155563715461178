import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import WL from 'constants/whiteLabel';
import globalStyles from 'pages/pages.module.css';
import styles from 'pages/install/install.module.css';
import Link from 'components/Link';
import CommandCopy from 'components/CommandCopy';

const Content = () => (
    <section className={cx(globalStyles.content, styles.wrapper)}>
        <div className={globalStyles.wrapper}>
            <div className={globalStyles.contentRow}>
                <div className={styles.titleContainer}>
                    <h3 className={styles.title}>
                        <FormattedMessage id="Content.title" defaultMessage="Connect" />
                    </h3>
                    <p>
                        <FormattedMessage id="Content.caption1" defaultMessage="To the worker" />
                    </p>
                </div>
                <div className={styles.content}>
                <p className={cx(styles.text, globalStyles.w100)}>
                    <FormattedMessage 
                        id="Content.caption5" 
                        defaultMessage="Default login is <code> user</code> password <code>1</code>"
                        values={{ code: (...chunks) => <code>{chunks}</code> }}
                    /><br />
                    <FormattedMessage 
                        id="Content.caption5_2" 
                        defaultMessage="Note: if you set your own password during the first run, you should use it instead."
                    />
                </p>
                <p className={cx(styles.text, globalStyles.w100)}>
                    <FormattedMessage 
                        id="Content.caption2" 
                        defaultMessage='Use <code>ssh user@192.168.0.xxx</code> from Linux or Mac. Windows users can use {puttyLink} ssh client. Once you are in run <code>sudo -s</code> to become <code>root</code> user. Start or stop miners, agent, etc only from root user.' 
                        values={
                            {
                                code: (...chunks) => {
                                    if (!chunks || chunks.includes('root')) {
                                        return <code>{chunks}</code>;
                                    } else {
                                        return <CommandCopy text={chunks} />
                                    }
                                },
                                puttyLink: <Link external href="https://www.putty.org/" className={globalStyles.link}>Putty</Link>
                            }
                        }
                    />
                </p>
                <p className={cx(styles.text, globalStyles.w100)}>
                    <FormattedMessage 
                        id="Content.caption3" 
                        defaultMessage="If you prefer graphic UI, VNC server is there for you. Use {vncViewerLink} or any other VNC client to connect to your IP address vnc://192.168.0.xxx:5900."
                        values={
                            {
                                vncViewerLink: <Link external href="https://www.realvnc.com/en/download/viewer/" className={globalStyles.link}>VNC Viewer</Link>
                            }
                        }
                    />
                </p>
                <p className={cx(styles.text, globalStyles.w100)}>
                    <FormattedMessage 
                        id="Content.caption4" 
                        defaultMessage="There is a Shellinabox server running on the rig. You may open <code>https://192.168.0.xxx:4200/</code> in browser and access shell from the web."
                        values={{ code: (...chunks) => <code>{chunks}</code> }}
                    />
                </p>
                <p className={cx(styles.text, globalStyles.w100)}>
                    <FormattedMessage 
                        id="Content.caption6" 
                        defaultMessage="And of course you can connect monitor to the first GPU in 16x slot if there some serious problems."
                    />
                </p>
                {
                    WL.showForumLinks ? (
                        <p className={cx(styles.text, globalStyles.w100)}>
                            <Link external noRef href="https://forum.hiveos.farm/discussion/22/security" className={globalStyles.link}>
                                <FormattedMessage id="Content.forumThreadLink" defaultMessage="Security forum thread." />
                            </Link>
                        </p>
                    ) : null
                }
                </div>
            </div>
        </div>
    </section>
)

export default Content;