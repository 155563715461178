import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import _keys from 'lodash/keys';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import Select from 'react-select';
import winImg from 'utils/images/windows_icon.svg';
import linImg from 'utils/images/linux-white.svg';
import questionImg from 'utils/images/question.svg';
import dlImg from 'utils/images/download.svg';
import Link from 'components/Link';
import style from './AhubDownloadList.module.css';
import cx from 'classnames';

const URL = '/ahub-version.json?v=' + Math.random();

const selectStyles = {
  singleValue: styles => ({
    ...styles,
    width: '100%',
    fontWeight: 'normal',
    paddingRight: '10px',
  }),
  control: styles => ({
    ...styles,
    boxShadow: 'none',
    minHeight: '48px',
    backgroundColor: 'transparent',
  }),
  menuList: styles => ({
    ...styles,
    paddingTop: '0',
    paddingBottom: '0',
    borderRadius: '3px',
    overflow: 'hidden',
    fontSize: '16px',
  }),
  container: styles => ({
    ...styles,
    minWidth: '150px',
    maxWidth: '320px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    maxHeight: '48px',
    padding: '0 4px',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    transition: 'all .25s ease-in',
    backgroundColor: isDisabled
      ? null
      : isSelected
        ? '#33373e'
        : isFocused ? 'rgba(40, 44, 52, 0.9)' : '#282C34',
    color: isDisabled
      ? '#878d98'
      : isSelected
        ? '#ccc'
        : isFocused
          ? '#ccc'
          : '#f1f1f1',
    ':active': {
      backgroundColor: 'rgba(40, 44, 52, 0.9)',
    },
    fontSize: '15px',
  }),
};

class AhubDownloadList extends Component {
  state = {
    items: [],
    selectedOs: undefined,
    isLoading: false,
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { isWindows, isLinux } = this.props;

    try {
      const res = await fetch(URL);
      const json = await res.json();
      const items = _get(json, 'artifacts');
      const keys = _keys(items);
      const localItems = _orderBy(_map(keys, (title = '') => ({
        title: title.split('-')[0],
        href: !!~title.indexOf('windows') ? 'https://download.hiveos.farm/hub/stable/latest/hub-windows-amd64.zip' : items[title].url,
        isWin: !!~title.indexOf('windows'),
        isLin: !!~title.indexOf('linux'),
      })), ['title'], ['asc']);
      
      this.setState({
        items: localItems,
        isLoading: false,
        ...(isWindows || isLinux ? {
          selectedOs: _find(localItems, i => i.isWin === isWindows || i.isLin === isLinux)
        } : {}),
      });
    } catch (e) {
      console.warn(e);
      this.setState({ isLoading: true });
    }
  }

  handleOsChange = value => {
    this.setState({ selectedOs: value });
  }

  optionLabelRenderer = (option) => {
    const {
      isWin,
      isLin,
    } = option;

    const imgSrc = isWin ? winImg : isLin ? linImg : questionImg;

    return (
      <div className={style.optionLink} target="_self">
        <div className={style.optionLinkImg}>
          <img src={imgSrc} alt="OS icon" />
        </div>
        <span className={style.optionTitle}>{option.title}</span>
      </div>
    )
  }

  render() {
    const { items, selectedOs, isLoading } = this.state;

    const {
      isWin,
      isLin,
      href,
    } = selectedOs || {};

    return (
      <Fragment>
        <Select
          styles={selectStyles}
          options={_uniqBy(items, 'title')}
          getOptionLabel={this.optionLabelRenderer}
          getOptionValue={option => option.href}
          isSearchable={false}
          menuPortalTarget={typeof document !== 'undefined' ? document.body : null}
          value={selectedOs}
          onChange={this.handleOsChange}
          isLoading={isLoading}
        />

        <div className={style.docs}>
          {
            isWin ? (
              <div className={cx(style.docsContent, style.isWin)}>
                <p><FormattedMessage id="ASIC.hub.windowsPreinstall" values={{
                  zip: <code>.zip</code>
                }} /></p>
                <div className={style.download}>
                  <Link external noRef href={href} target="_self">
                    <FormattedMessage id="ASIC.hub.downloadInstaller" />
                    <img src={dlImg} alt="download icon"/>
                  </Link>
                </div>
                <p><FormattedMessage id="ASIC.hub.windowsPostinstall1" values={{
                  exe: <code>.exe</code>
                }} /></p>
                <p>
                  <FormattedMessage
                    id="ASIC.hub.postinstall"
                    defaultMessage="After installation you can start using ASIC Hub. For more information please check our {link}."
                    values={{
                      link: <Link to="/ASIC-Hub" withLang><FormattedMessage id="ASIC.hub.postinstallKb" /></Link>
                    }}
                  />
                </p>
              </div>
            ) : null
          }

          {
            isLin ? (
              <div className={cx(style.docsContent, style.isLin)}>
                <p><FormattedMessage id="ASIC.hub.simpleInstall" defaultMessage="Run this script to download and install ASIC Hub." /></p>
                <pre>curl -f https://download.hiveos.farm/hub/install.sh | sh</pre>
                <p>
                  <FormattedMessage
                    id="ASIC.hub.postinstall"
                    defaultMessage="After installation you can start using ASIC Hub. For more information please check our {link}."
                    values={{
                      link: <Link to="/ASIC-Hub" withLang><FormattedMessage id="ASIC.hub.postinstallKb" /></Link>
                    }}
                  />
                </p>
              </div>
            ) : null
          }
        </div>
      </Fragment>
    );
  }
}


export default AhubDownloadList;
