import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import Img from 'components/Image';
import globalStyles from 'pages/pages.module.css';
import styles from 'pages/install/install.module.css';

const Register = () => (
    <section className={cx(globalStyles.content, styles.wrapper)}>
        <div className={globalStyles.wrapper}>
            <div className={globalStyles.contentRow}>
                <div className={styles.titleContainer}>
                <h3 className={styles.title}>
                    <FormattedMessage id="Register.register" defaultMessage="Register" /> <span>&</span>
                </h3>
                <h3 className={styles.title}>
                    <FormattedMessage id="Register.manage" defaultMessage="Manage" />
                </h3>
                </div>
                <div className={styles.content}>
                <Img src="screens/new-rig.png" className={styles.img} alt="new rig"/>
                <p className={cx(styles.text, globalStyles.w100)}>
                    <FormattedMessage id="Register.caption" defaultMessage="Manage and configure each individual rig up to your arrangement profile. Overclock AMD and Nvidia GPUs from the web, troubleshoot and reboot GPU errors. Perform bulk updates, pool changes or setup automatic tasks" />
                </p>
                </div>
            </div>
        </div>
    </section>
)

export default Register;
