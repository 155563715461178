import React, { Component } from 'react';
import Select, { components } from 'react-select';
import dlImg from 'utils/images/download.svg';
import Link from 'components/Link';
import style from './DownloadList.module.css';

const selectStyles = {
  singleValue: styles => ({
    ...styles,
    width: '100%',
    fontWeight: 'normal',
    paddingRight: '10px',
  }),
  control: styles => ({
    ...styles,
    boxShadow: 'none',
    minHeight: '48px',
    backgroundColor: 'transparent',
  }),
  menuList: styles => ({
    ...styles,
    paddingTop: '0',
    paddingBottom: '0',
    borderRadius: '3px',
    overflow: 'hidden',
    fontSize: '16px',
  }),
  container: styles => ({
    ...styles,
    minWidth: '150px',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    maxHeight: '48px',
    padding: '0 4px',
  }),
  placeholder: styles => ({
    ...styles,
    fontWeight: '700',
    fontSize: '13px',
    color: '#000',
    opacity: '1',
  }),
};

class DownloadList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMount: false,
    };
  }

  componentDidMount () {
    this.setState({
      isMount: true,
    });
  }

  optionLabelRenderer = (option) => {
    return (
      <Link external noRef href={option.href} className={style.optionLink} target="_self">
        <span className={style.optionTitle}>{option.title}</span>
        <img src={dlImg} alt="download icon"/>
      </Link>
    )
  }

  Menu = props => {
    return (
      <components.Menu {...props}>
        <div className={style.menuList}>
          {props.children}
        </div>
      </components.Menu>
    );
  };

  Placeholder = props => {
    const { children } = this.props;

    return (
      <components.Placeholder {...props}>
        { children }
      </components.Placeholder>
    );
  };

  Option = props => {
    return (
      <div className={style.option}>
        {props.children}
      </div>
    );
  };

  render() {
    const { options } = this.props;

    const {
      isMount,
    } = this.state;

    return isMount ? (
        <Select
          styles={selectStyles}
          options={options}
          getOptionLabel={this.optionLabelRenderer}
          isSearchable={false}
          components={{
            Menu: this.Menu,
            Placeholder: this.Placeholder,
            Option: this.Option,
          }}
          menuPortalTarget={typeof document !== 'undefined' ? document.body : null}
        />
    ) : null;
  }
}


export default DownloadList;
