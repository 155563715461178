import React from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import Layout from 'components/layout'

import Hero from 'components/Layout/Hero';

import ASIC from 'components/Install/ASIC';
import Ahub from 'components/Install/Ahub';
import GPU from 'components/Install/GPU';
import Guides from 'components/Install/Guides';
import Register from 'components/Install/Register';
import DeployPxe from 'components/Install/DeployPxe';
import DisklessPxe from 'components/Install/DisklessPxe';
import Beta from 'components/Install/Beta';
import Flashing from 'components/Install/Flashing';
import Content from 'components/Install/Content';
import Write from 'components/Install/Write';
import Bottom from 'components/Bottom/Bottom';
import GetStarted from 'components/GetStarted';
import WL from 'constants/whiteLabel';

class Install extends React.Component {
  render () {
    return (
      <Layout page="install" lang={_get(this.props, 'pageContext.lang', 'en')}>
        <Hero
          headerIntl={<FormattedMessage id="install.install" defaultMessage="Install" />}
          subHeaderIntl={<FormattedMessage id="install.caption" defaultMessage="Control your ASICs and GPUs from anywhere" />}
        />
        <div>
          {WL.hasAsics() && <ASIC />}
          {WL.hasAhub() && <Ahub />}
          {WL.hasBeta() && <GPU />}
          {WL.hasBeta() && <Beta />}
          {WL.hasGuides() && <Guides />}
          {WL.hasBeta() && <Flashing />}
          {WL.hasBeta() && <Write />}
          {WL.hasBeta() && <Content />}
          {WL.hasBeta() && <Register />}
          {WL.hasBeta() && <DeployPxe />}
          {WL.hasBeta() && <DisklessPxe />}
          <GetStarted/>
          <Bottom/>
        </div>
      </Layout>
    )
  }
}

export default Install;
