import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import WL from 'constants/whiteLabel';
import globalStyles from 'pages/pages.module.css';
import styles from 'pages/install/install.module.css';
import s from './GPU.module.css';
import Link from 'components/Link';

const GPU = () => (
  <section className={cx(globalStyles.content, styles.wrapper, s.root)}>
    <div className={globalStyles.wrapper}>
      <div className={globalStyles.contentRow}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>GPU</h3>
        </div>
        <div className={styles.content}>
          <p style={{width: "100%", marginBottom: "10px"}}>
            <FormattedMessage
              id="GPU.caption1"
              defaultMessage="Hive OS Image for Rigs. {stableVersion}"
              values={{ stableVersion: <span style={{fontWeight: "600", color: "#fff"}}><FormattedMessage id="GPU.stableVersion" defaultMessage="Stable Version" /></span> }}
            />
          </p>
          <Link external noRef style={{maxHeight: "50px"}} href={WL.getDownloadLatestTorrentLink()} className={cx(globalStyles.btnPrimary, globalStyles.w45)} target="_self">
            <span className={globalStyles.whiteColor}>Torrent</span>
          </Link>
          <Link external noRef style={{maxHeight: "50px"}} href={WL.getDownloadLatestZipLink()} className={cx(globalStyles.btnPrimary, globalStyles.w45)} target="_self">
            <span className={globalStyles.whiteColor}>ZIP</span>
          </Link>
          <p style={{width: "100%", marginBottom: "0"}}>
            <FormattedMessage id="GPU.readAbout" defaultMessage="Read about" />
            &nbsp;
            <a href="#howto-image" className={globalStyles.link}>
              <FormattedMessage id="GPU.howWriteImage" defaultMessage="How to Write Image" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default GPU;
