import React, { Fragment } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import AsicDownloadList from 'components/Install/AsicDownloadList';
import AsicBtn from 'components/AsicBtn';
// import Link from 'components/Link';
import github from 'utils/images/github.svg';
import globalStyles from 'pages/pages.module.css';
import WL, { WL_BIGMINER, WL_HIVEOS } from 'constants/whiteLabel';
import styles from 'pages/install/install.module.css';
import s from 'components/Install/ASIC/ASIC.module.css';

const ASIC = () => (
  <section className={cx(globalStyles.content, styles.wrapper)}>
    <div className={globalStyles.wrapper}>
      <div className={globalStyles.contentRow}>
        <div className={styles.titleContainer}>
        {
          WL.key === WL_HIVEOS ? (
            <h3 className={styles.title}>ASIC</h3>
          ) : null
        }
        {
          WL.key === WL_BIGMINER ? (
            <h3 className={styles.title}><FormattedMessage id="ASIC.title1" defaultMessage="Download" /></h3>
          ) : null
        }
        </div>
        <div className={styles.content}>
          <div className={cx(styles.dropdown/*, s.mb50*/)}>
            {
              WL.key === WL_HIVEOS ? (
                <Fragment>
                  <p style={{width: "100%", marginBottom: "10px"}}>
                    <FormattedMessage id="ASIC.caption1" defaultMessage="Hive OS client for ASICs" />
                  </p>
                  <AsicBtn href="https://github.com/minershive/hiveos-asic">
                    <img style={{ margin: '-1px 7px 0 -10px' }} src={github} width="30" height="30" alt="github icon" />
                    <FormattedMessage
                      id="ASIC.github_repo"
                      defaultMessage="<span>Github</span> Repository & Install Client"
                      values={{ span: (...chunks) => <span>{chunks}&nbsp;</span> }}
                    />
                  </AsicBtn>
                </Fragment>
              ) : null
            }
            {
              WL.key === WL_BIGMINER ? (
                <Fragment>
                  <p style={{width: "100%", marginBottom: "10px"}}>
                    <FormattedMessage id="ASIC.caption1-bm" defaultMessage="Hive OS client for ASICs" />
                  </p>
                  <AsicBtn href="https://download.bigminer.io/latest">
                    <FormattedMessage id="ASIC.caption2-bm" defaultMessage="Common GPU Mirror download" />
                  </AsicBtn>
                </Fragment>
              ) : null
            }
          </div>

          <div className={s.mb10}>
            {
              WL.key === WL_BIGMINER ? (
                <div className={styles.dropdown}>
                  <p style={{width: "100%", marginBottom: "10px"}}>
                    <FormattedMessage id="ASIC.caption5-bm" defaultMessage="Hive OS client for ASICs" />
                  </p>
                  <AsicBtn href="http://www.bigminer.io/asic_zh/">
                    <FormattedMessage id="AsicDownloadList.placeholder" defaultMessage="Download Firmware" />
                  </AsicBtn>
                </div>
              ) : null
            }
            {
              WL.key === WL_HIVEOS ? (
                <div className={styles.dropdown}>
                  <div className={cx(styles.dropdown, s.mbn10/*, s.mb10*/)}>
                    <p style={{width: "100%", marginBottom: "10px"}}>
                      <FormattedMessage id="ASIC.caption2" defaultMessage="Hiveon ASIC Firmware" />
                    </p>
                    <AsicDownloadList />
                  </div>
                  {/* <p className={s.mb5}>
                    <FormattedMessage id="GPU.readAbout" defaultMessage="GPU.readAbout" />
                    &nbsp;
                    <Link
                      href="https://hiveos.farm/hiveon-asic-firmware/"
                      external
                    >
                      <FormattedMessage id="ASIC.caption2" defaultMessage="ASIC.caption2" />
                    </Link>
                  </p>
                  <p className={s.mb0}>
                    <FormattedMessage id="More Firmware" defaultMessage="More Firmware" />
                    &nbsp;
                    <Link withLang to="/asic">
                      <FormattedMessage id="here" defaultMessage="here" />
                    </Link>
                  </p> */}
                </div>
              ) : null
            }
          </div>

          {
            WL.key === WL_BIGMINER ? (
              <div className={styles.dropdown} style={{ marginTop: '30px', }}>
                <p style={{width: "100%", marginBottom: "10px"}}>
                  <FormattedMessage id="ASIC.caption3-bm" defaultMessage="Mirror making tool" />
                </p>
                <AsicBtn href={WL.etcherLink}>
                  <FormattedMessage id="ASIC.caption4-bm" defaultMessage="Download" />
                </AsicBtn>
              </div>
            ) : null
          }

          {
            WL.key === WL_BIGMINER ? (
              <div className={styles.dropdown} style={{ marginTop: '30px', }}>
                <p style={{width: "100%", marginBottom: "10px"}}>
                  <FormattedMessage id="Flasher" defaultMessage="Flasher" />
                </p>
                <AsicBtn href="https://download.bigminer.io/hive-flasher-20210421.zip">
                  <FormattedMessage id="ASIC.caption4-bm" defaultMessage="Download" />
                </AsicBtn>
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  </section>
)

export default ASIC;
