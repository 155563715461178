import WL, { WL_BIGMINER, WL_HIVEOS } from 'constants/whiteLabel';

export const ITEMS_HIVEOS = [
  {
    title: 'S17 / S17 PRO',
    href: 'https://download.hiveos.farm/asic/s17/Antminer-S17-S17pro-s17p-Hiveon-latest.tar.gz',
  },
  {
    title: 'S17+',
    href: 'https://download.hiveos.farm/asic/S17+/Antminer-S17Plus-Hiveon-latest.tar.gz',
  },
  {
    title: 'S17е',
    href: 'https://download.hiveos.farm/asic/S17E/Antminer-S17E-Hiveon-latest.tar.gz',
  },
  {
    title: 'T17',
    href: 'https://download.hiveos.farm/asic/T17/Antminer-T17-Hiveon-latest.tar.gz',
  },
  {
    title: 'T17+',
    href: 'https://download.hiveos.farm/asic/T17+/Antminer-T17Plus-Hiveon-latest.tar.gz',
  },
  {
    title: 'T17е',
    href: 'https://download.hiveos.farm/asic/T17E/Antminer-T17E-Hiveon-latest.tar.gz',
  },
  {
    title: 'L3+, L3++',
    href: 'https://download.hiveos.farm/asic/L3+/Antminer-L3Plus-Hiveon-latest.tar.gz',
  },
  {
    title: 'S9/S9i/S9j',
    href: 'https://download.hiveos.farm/asic/s9/Antminer-S9-all-Hiveon-1.02-sign.tar.gz',
  },
  {
    title: 'S10',
    href: 'https://download.hiveos.farm/asic/S10/Antminer-S10-all-Hiveon-1.02-sign.tar.gz',
  },
  {
    title: 'T9+',
    href: 'https://download.hiveos.farm/asic/t9/Antminer-T9-all-Hiveon.tar.gz',
  }
];
export const ITEMS_BIGMINER = [
  {
    title: 'S17 / S17 PRO',
    href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-S17-S17pro-v1.2.0h_CRC_SIG-sig-2.03%40201222-S17-sign-f2pool.tar.gz',
  },
  // {
  //   title: 'S17+',
  //   href: 'https://download.bigminer.io/asic/S17+/Antminer-S17Plus-Bigminer-latest.tar.gz',
  // },
  {
    title: 'S17е',
    href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer_S17E-v1.2.0j_CRC_SIG-sig-2.03%40201222-S17E-sign-f2pool.tar.gz',
  },
  // {
  //   title: 'L3+, L3++',
  //   href: 'https://download.bigminer.io/asic/L3+/Antminer-L3Plus-Bigminer-latest.tar.gz',
  // },
  {
    title: 'T17',
    href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-T17-v1.2.0h_CRC_SIG-sig-2.03%40201222-T17-sign-f2pool.tar.gz',
  },
  {
    title: 'T17e',
    href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer_T17E-v1.2.0j_CRC_SIG-sig-2.03%40201222-T17E-sign-f2pool.tar.gz',
  },
  {
    title: 'S9/S9i/S9j',
    href: 'https://download.bigminer.io/asic/custom/f2pool/201222/Antminer-S9-all-Bigminer-1.03%40201222-sign-f2pool.tar.gz',
  },
];

export const ITEMS_MAP = {
  [WL_BIGMINER]: ITEMS_BIGMINER,
  [WL_HIVEOS]: ITEMS_HIVEOS,
};

export const ITEMS = ITEMS_MAP[WL.key] || ITEMS_HIVEOS;