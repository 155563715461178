import React from 'react';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import WL from 'constants/whiteLabel';
import languages from 'constants/languages';
import globalStyles from 'pages/pages.module.css';
import styles from 'pages/install/install.module.css';
import Link from 'components/Link';
import CommandCopy from 'components/CommandCopy';

const LINK = {
  [languages.RU]: 'https://hiveos.farm/guides-hdd_move_ru/',
  [languages.EN]: 'https://hiveos.farm/guides-hdd_move/'
};

const Write = props => (
  <section id="howto-image" style={{marginTop: "50px"}} className={cx(globalStyles.content, styles.wrapper)}>
    <div className={globalStyles.wrapper}>
      <div className={globalStyles.contentRow}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>
            <FormattedMessage id="Write.title" defaultMessage="Write" />
          </h3>
          <p>
            <FormattedMessage id="Write.caption1" defaultMessage="GPU Rigs Disk Image" />
          </p>
        </div>
        <div className={styles.content}>

        <p className={cx(styles.text, globalStyles.w100)}>
          <FormattedMessage 
            id="Write.caption2" 
            defaultMessage="{etcherLink} is a recommended tool to write Hive images to drives. It supports Windows, Mac, Linux. It can write unpacked ZIP archive as well."
            values={{ etcherLink: <Link external href="https://www.balena.io/etcher/" className={globalStyles.link}>Etcher</Link> }}
          />
        </p>
        <p className={cx(styles.text, globalStyles.w100)}>
          <FormattedMessage 
            id="Write.caption3" 
            defaultMessage="Alternatively you can use {rufusLink} as image writing tool."
            values={{ rufusLink: <Link external href="https://rufus.akeo.ie/" className={globalStyles.link}>Rufus</Link> }}
          />
        </p>
        <p className={cx(styles.text, globalStyles.w100)}>
          <FormattedMessage 
            id="Write.caption4" 
            defaultMessage="After the image is flashed you will discover newly created drive in your system where you will be able to precreate <code>rig.conf</code> with worker's ID and password or FARM_HASH. Please find rig-config-example.txt on HIVE drive."
            values={{ code: (...chunks) => <code>{chunks}</code> }}
          />
        </p>
        <p className={cx(styles.text, globalStyles.w100)}>
          <FormattedMessage 
            id="Write.caption12" 
            defaultMessage='Advanced Unix (Linux or Mac) users can use <code>dd</code> to write RAW image to disk. Something like <code>dd if=hive-xxx.img of=/dev/sdb bs=10M status=progress</code>. But be careful finding out output disk "of", use <code>fdisk -l</code> to list your partitions.'
            values={{ code: (...chunks) => <CommandCopy text={chunks} /> }}
          />
        </p>
        <p className={cx(styles.text, globalStyles.w100)}>
          <FormattedMessage 
            id="Write.caption6" 
            defaultMessage="SSDs are much more reliable and advised to use in production environments. If you want your USB flash drive please run <code>logs-off</code> after installation."
            values={{ code: (...chunks) => <CommandCopy text={chunks} /> }}
          />
        </p>
        {
          WL.showForumLinks ? (
            <p className={cx(styles.text, globalStyles.w100)}>
              <FormattedMessage 
                id="Write.caption7" 
                defaultMessage="{bootingProblemsLink} forum thread."
                values={{ bootingProblemsLink: <Link external noRef href="https://forum.hiveos.farm/discussion/6/booting-problems" className={globalStyles.link}><FormattedMessage id="Write.bootingProblems" defaultMessage="Common booting problems" /></Link> }}
              />
            </p>
          ) : null
        }
        <p className={cx(styles.text, globalStyles.w100)}>
          <FormattedMessage 
            id="Write.caption11" 
            values={{ link: (
              <Link external noRef href={LINK[props.intl.locale]} className={globalStyles.link}>
                <FormattedMessage id="This manual" />
              </Link>
            )}}
          />
        </p>
        </div>
      </div>
    </div>
  </section>
)

export default injectIntl(Write);
