import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import hivemsk from 'utils/images/hivemsk.svg';
import { ITEMS } from 'components/Install/AsicDownloadList/asicsList';
import DownloadList from 'components/Install/DownloadList';
import style from './AsicDownloadList.module.css';

class AsicDownloadList extends Component {
  render() {
    return (
     <DownloadList options={ITEMS}>
      <div className={style.placeholder}>
        <img className={style.placeholderImg} src={hivemsk} width="30px" height="30px" alt="hivemsk icon"/>
        <span className={style.placeholderText}>
          <FormattedMessage id="AsicDownloadList.placeholder" defaultMessage="Download Firmware" />
        </span>
      </div>
     </DownloadList>
    )
  }
}


export default AsicDownloadList;
