import React from 'react';
import s from './AsicBtn.module.css';

const AsicBtn = ({href, children, ...rest}) => (
  <a target="_blank" href={href} className={s.root} {...rest} rel="nofollow noopener noreferrer">
    <span className={s.wrap}>{children}</span>
  </a>
);

export default AsicBtn;
