import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import globalStyles from 'pages/pages.module.css';
import styles from 'pages/install/install.module.css';
import Link from 'components/Link';

const DeployPxe = () => (
    <section className={cx(globalStyles.content, styles.wrapper)}>
        <div className={globalStyles.wrapper}>
            <div className={globalStyles.contentRow}>
                <div className={styles.titleContainer}>
                <Link external noRef href="https://forum.hiveos.farm/t/hive-os-deploy-pxe/12444">
                  <h3 className={styles.title}>
                    <FormattedMessage id="DeployPxe.title" defaultMessage="Deploy PXE" />
                  </h3>
                </Link>
                </div>
                <div className={styles.content}>
                  <p className={cx(styles.text, globalStyles.w100)}>
                    <FormattedMessage id="DeployPxe.caption1" defaultMessage="Need to deploy hundreds or thousands of rigs with HiveOS?" />
                  </p>
                  <p className={cx(styles.text, globalStyles.w100)}>
                    <FormattedMessage 
                      id="DeployPxe.caption2" 
                      defaultMessage="Use {deployPxeLink} for deployment over the network, gaining complete control over the process and significantly reducing deployment time"
                      values={{ deployPxeLink: <Link external noRef href="https://forum.hiveos.farm/t/hive-os-deploy-pxe/12444" className={globalStyles.link}>Deploy PXE</Link> }}
                    />
                  </p>
                </div>
            </div>
        </div>
    </section>
)

export default DeployPxe;
