import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import Link from 'components/Link';
import globalStyles from 'pages/pages.module.css';
import styles from 'pages/install/install.module.css';
import github from 'utils/images/github-logo.svg';

const Flashing = () => (
  <>
    <section className={cx(globalStyles.content, styles.wrapper)}>
      <div className={globalStyles.wrapper}>
        <div className={globalStyles.contentRow}>
          <div className={styles.titleContainer}>
            <h3  style={{fontSize:"38px"}} className={styles.title}>
              <FormattedMessage id="Flashing.title" defaultMessage="Flashing Utility" />
            </h3>

          </div>
          <div className={styles.content}>
            <p style={{width: "100%"}}>
              <FormattedMessage id="Flashing.caption1" defaultMessage="Hive OS Drive Flashing Utility" />
            </p>
            <Link external href="https://github.com/minershive/hive-flasher" className={cx(globalStyles.btnPrimary, globalStyles.btnGitHub)}>
              <img src={github} alt="Hive ASIC icon" className={globalStyles.btnPrimaryImage}/>
              <span>
                <FormattedMessage 
                  id="Flashing.caption2" 
                  defaultMessage="Hive OS {flashingLink}" 
                  values={{ flashingLink: <span className={globalStyles.whiteColor}><FormattedMessage id="Flashing.flashingLink" defaultMessage="Drive Flashing Utility" /></span> }}
                />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className={cx(globalStyles.content, styles.wrapper)}>
      <div className={globalStyles.wrapper}>
        <div className={globalStyles.contentRow}>
          <div className={styles.titleContainer}>
          </div>
          <div className={styles.content} style={{paddingTop:"15px"}}>
          <p style={{marginBottom: "0"}} className={cx(globalStyles.listOfFiles, globalStyles.w100)}>
            <FormattedMessage id="Flashing.caption3" defaultMessage="Browse download directory" /> <Link external noRef href="https://download.hiveos.farm/" target="_self" className={globalStyles.link}>download.hiveos.farm/</Link>
          </p>
          <p className={cx(globalStyles.listOfFiles, globalStyles.w100)}>
            <FormattedMessage id="Flashing.caption4" defaultMessage="Historical releases can be found here" /> <Link external noRef href="https://download.hiveos.farm/history/" target="_self" className={globalStyles.link}>download.hiveos.farm/history/</Link>
          </p>
          <p className={cx(globalStyles.listOfFiles, globalStyles.w100)}><FormattedMessage id="Flashing.caption5" defaultMessage="MD5 Sums and driver versions to check" /> <Link external href="https://download.hiveos.farm/VERSIONS.txt" target="_blank" className={globalStyles.link}>download.hiveos.farm/VERSIONS.txt</Link></p>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Flashing;
